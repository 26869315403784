.p-editor-container .p-editor-toolbar {
    background: inherit !important;

}
.p-editor-container .p-editor-content .ql-editor {
    background: inherit !important;
}
.mbsc-ios.mbsc-textfield-inner.mbsc-disabled {
    opacity:1 !important;
    cursor:default;
}

.p-button-icon{
    padding-left:1em !important;

}
.mbsc-event-calendar {
    z-index: 1 !important; /* Lower the z-index of the Eventcalendar */
}


.top-menu-options-wrapper {
    height:34px;
}
.top-menu-options-wrapper .p-menubar-root-list .p-menuitem {
    border:1px solid black;

    border-radius: 6px;
}
.top-menu-options-wrapper .p-submenu-list .p-menuitem {
    width:100%;
    min-height: 34px;
}


.p-menu{
    width: 100% !important;
}
#searchFreightBillTable .p-column-filter-menu-button{
    display:none !important;
}
#searchFreightBillTable .p-fluid{
    width:100% !important
}
.mbsc-row{
    width:100%;
}
.p-button-icon-only {
    height:2em;
    justify-content: left !important;
}

.mbsc-ios.mbsc-input {
    background: transparent !important;
}
.mbsc-ios.mbsc-input input {
    background: transparent !important
}
.mbsc-mobiscroll .mbsc-lv-item {
    padding:0 !important
}
.p-card .p-card-body {
    padding: .25rem !important;
}
.mbsc-ios.mbsc-textfield-wrapper-box, .mbsc-ios.mbsc-textfield-wrapper-outline {
    margin:0 !important;
}
.mbsc-control-w:hover {
    /* add any styles you want to apply on hover */
    background-color: #ddd;
  }

.signatureCanvas{
    background-color:lightgrey;
    border: 1px solid black;
    width:100%;

}
.md-cancelled {
    position: absolute;
    top: 0px;
    width:50em;
    height:15em;
    left: 180px;
    z-index:999;
    font-size: 5px;
}
   
.for-load{
    width:53.5% !important
}
.for-time-label{
    width:64.2% !important
}
.for-freight-label{
    width:55.5% !important
}
.for-freight-time-label{
    width:115% !important
}
.mobile-inputgroup{
	height:3em !important;
}
.mobile-inputgroup-textarea{
	height:5em !important;
}
.for-load-order-label{
    width:61% !important
}
.react-pdf__Page__canvas{
    width:100% !important;
    max-width: 595px !important;
}



.custom-item-class {
    padding: 0px;
    cursor: pointer;
}

.dispatch-inputgroup{
    white-space: nowrap;



}


.comp-auto-input{
    flex-basis:29.5%
}



.material-label{
    flex-basis:37.5%;
    flex-shrink: 0; /* Prevent shrinking */
    flex-grow: 0; /* Prevent growing */
    display: flex;
    align-items: center;
    justify-content: center;
    background: #f3f4f6;
    color: #6b7280;
    border-top: 1px solid #d1d5db;
    border-left: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
    padding: 0.75rem 0.75rem;
  
}


.p-menubar .p-submenu-list {

    z-index: 1000; /* Ensure the Menubar dropdowns are on top */
}

.p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
    
    padding: .5rem 1rem;
}
.p-menubar-end{

   z-index: 100;
}
.p-checkbox {
    width:100% !important;
    padding:.2em;
    height:2em;
 
}
.p-inputnumber-input {
    width: 100%;
}
.p-checkbox .p-checkbox-box {
    height:100% !important;
    width:2em !important;
}
.p-inputgroup-addon{
    flex-basis: 25%;
    flex-shrink: 0; /* Prevent shrinking */
    flex-grow: 0; /* Prevent growing */

}
.p-card-content{
    padding-top:.5em;
    padding-bottom:.5em;
}
.p-checkbox.p-component.p-disabled {
    background-color: white; /* Replace with the color you want */
}
.p-checkbox .p-checkbox-input {
    border:0;
}

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input {
    flex: 1 1 auto;
   
}
.p-inputgroup {
    padding:0;
    height:2em;
}
.p-checkbox.p-disabled .p-checkbox-box {
    z-index:1;
    background-color: #0000001c; /* Replace with the color you want */
}
.p-checkbox.p-disabled.p-highlight .p-checkbox-box {
    background-color: #6366f1; /* Replace with the color you want */
}

.p-checkbox .p-checkbox-input:disabled {
    background: inherit !important;
    background-color: inherit!important; /* Your grey background */

}

.p-checkbox .p-checkbox-input:disabled  .p-checkbox-icon {
    color:black;
}
.p-checkbox .p-checkbox-box:disabled  .p-checkbox-icon {

    color: #000 !important; /* Set the color of the checkmark to black or another contrasting color */
    opacity: 1 !important; /* Ensure the checkmark is fully opaque */
}

.dispatch-small-inputgroup{
    white-space: nowrap;
    flex-basis: 50%;
  

}


.freight-panel .p-panel-header{
    padding-top:.75em;
    padding-bottom:.75em;
}
.freight-inputgroup{
    white-space: nowrap;
    flex-basis: 40%;

}
.search-auto-input{
    flex-basis:40%
}
span.p-dropdown-label {
    padding-top: .2em !important;
}
.p-tabview .p-tabview-nav li .p-tabview-nav-link {
    height:100%;
    padding-top:.5em;
    padding-bottom:.5em;
    padding-left:.75em;
    padding-right:.75em;
}

.ql-disabled .ql-editor p{
    cursor: default;
    background-color: lightgrey;
}
.ql-editor {
    padding:.5em !important;
}
.weight-material{
    white-space: nowrap;
    width:65% !important;
}
.ql-container {
    height: inherit;
}

.text-area-inputgroup{
    height:4em;
}
.scale-tag-button{
    height: 6.5em !important;
}

.mbsc-row{
    margin-left:0 !important;
}
.mbsc-windows.mbsc-textarea {
    height:4em !important;
}
.mbsc-windows.mbsc-textfield-wrapper {
    margin:0 !important;
    width:100% !important;
}
.dispatch-status-inputgroup{
    height:3.2em;
}
.dispatch-status-inputgroup-addon{
    white-space: nowrap;
    flex-basis: 55%;
    height:3.2em !important;
    padding:1.1em  !important;
}

.mbsc-textfield-inner {
display: initial !important;
}
.mbsc-ios.mbsc-textarea {

    height: 4em !important;
}
.textarea{
    margin: 0;

}
textarea:disabled{
    font-weight:bold;
    opacity: 0.8 !important;
}

table, th, td {
    border: 1px solid #dee2e6;

  }
.tr{
  border-bottom:'1px solid #dee2e6';
}
.td{
    border-right:'1px solid #dee2e6';
}
.p-steps-item {
    background: transparent !important;
}
.p-disabled, .p-component:disabled {
    background:#0000001c;
    opacity:1 !important;
}
input:disabled {
    font-weight:bold;
    background:#e3e3e3 !important;
    opacity:1;
}


.p-dialog .p-dialog-content {
    padding:0 !important;
}
  .disabledLabel{

    background:#0000001c !important;
    opacity:1 !important;
}
.paperlessInput{
    width:100%;
 
}
.mbsc-ios.mbsc-textfield-wrapper {
width: 100%;
}


.mbsc-ios.mbsc-label.mbsc-disabled {
    opacity:1 !important;
}
.mbsc-ios.mbsc-input input:disabled{
    opacity:.8 !important;
}

.p-overlaypanel .p-overlaypanel-content {
    padding:0 !important;
}
.mbsc-col-1, .mbsc-col-2, .mbsc-col-3, .mbsc-col-4, .mbsc-col-5, .mbsc-col-6, .mbsc-col-7, .mbsc-col-8, .mbsc-col-9, .mbsc-col-10, .mbsc-col-11, .mbsc-col-12, .mbsc-col, .mbsc-col-auto, .mbsc-col-sm-1, .mbsc-col-sm-2, .mbsc-col-sm-3, .mbsc-col-sm-4, .mbsc-col-sm-5, .mbsc-col-sm-6, .mbsc-col-sm-7, .mbsc-col-sm-8, .mbsc-col-sm-9, .mbsc-col-sm-10, .mbsc-col-sm-11, .mbsc-col-sm-12, .mbsc-col-sm, .mbsc-col-sm-auto, .mbsc-col-md-1, .mbsc-col-md-2, .mbsc-col-md-3, .mbsc-col-md-4, .mbsc-col-md-5, .mbsc-col-md-6, .mbsc-col-md-7, .mbsc-col-md-8, .mbsc-col-md-9, .mbsc-col-md-10, .mbsc-col-md-11, .mbsc-col-md-12, .mbsc-col-md, .mbsc-col-md-auto, .mbsc-col-lg-1, .mbsc-col-lg-2, .mbsc-col-lg-3, .mbsc-col-lg-4, .mbsc-col-lg-5, .mbsc-col-lg-6, .mbsc-col-lg-7, .mbsc-col-lg-8, .mbsc-col-lg-9, .mbsc-col-lg-10, .mbsc-col-lg-11, .mbsc-col-lg-12, .mbsc-col-lg, .mbsc-col-lg-auto, .mbsc-col-xl-1, .mbsc-col-xl-2, .mbsc-col-xl-3, .mbsc-col-xl-4, .mbsc-col-xl-5, .mbsc-col-xl-6, .mbsc-col-xl-7, .mbsc-col-xl-8, .mbsc-col-xl-9, .mbsc-col-xl-10, .mbsc-col-xl-11, .mbsc-col-xl-12, .mbsc-col-xl, .mbsc-col-xl-auto {
    padding-left:.1em ;
    padding-right:.1em ;
}

.tableList{
   padding:0;
    list-style-type: none;
    margin:0 !important;
    padding-left:.25em
}
.listItem {
    padding:.15em;
    border-top: 1px solid #ccc;
}
.listItem:hover{
    
    background-color: #ebebeb;
}
.p-panel .p-panel-content {
    padding:0 !important;
}

.p-tabview .p-tabview-nav {
    margin:0 !important;
}
.p-tabview .p-tabview-panels {
    padding:0 !important;
}

.p-textarea-addon{
    background: #f3f4f6;
    color: #6b7280;
    border-top: 1px solid #d1d5db;
    border-left: 1px solid #d1d5db;
    border-bottom: 1px solid #d1d5db;
    padding: 0.75rem 0.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 3rem;
    flex-basis: 25%;
    flex-shrink: 0; /* Prevent shrinking */
    flex-grow: 0; /* Prevent growing */

}
.p-accordion-header-link{
    padding:.5em
}
.p-accordion-content{
    padding:0;
}
.homeSearch{
    display:none;
}
.accountNotesStyle{
    height:5em;
    padding-top:.5em;
}
.popUpButton{
    font-size:1.5em;
    width:9em;
}

.p-dialog .p-dialog-footer {
    padding:0;
    padding-bottom:.5em;
}
.p-multiselect .p-multiselect-label {
    padding-top:.4em;
}
.p-multiselect .p-multiselect-label.p-placeholder {
    padding-top:.4em;
}
@media screen and (max-width: 1600px) {
    .p-menubar .p-menuitem > .p-menuitem-content .p-menuitem-link {
        padding: .5rem .5rem;
        font-size:.9em;
    }
   
    .top-menu-options-wrapper .p-menubar-root-list {
        height:100%;
        padding:0;
        left: auto !important; /* Override default left alignment */
        right: 0; /* Align the dropdown to the right edge */
        font-size:.8em;
        font-weight:bold;
        top:0;
        width: 100%;
        display:inline-flex;
        position:relative;

    }
    .top-menu-options-wrapper .p-menuitem-content{
        min-height:30px;
        height:100%;
    }
    .top-menu-options-wrapper .p-menuitem-link{
        padding:0 !important;
        height:100%;
    }
    .top-menu-options-wrapper .p-menuitem {
        width:33%;
        height:100%;
        position:relative;
    }
    .custom-menubar{
        padding:0;
    }
    .top-menu-options-wrapper .p-menubar-button  {
        display:none;
    }

    .p-menubar-end{
        width:30%
    }
    .submenu-item{
        padding-left:3em !important;
    }
    .sub-submenu-item{
        padding-left:5em !important;
    }
}
@media screen and (max-width: 1250px) {
    .p-menubar-end{
        width:50%
    }
    .material-label{
        flex-basis:25%;
       
      
    }
}
@media screen and (max-width: 960px) {
    .material-label{
        flex-basis:25%;
       
      
    }
    .top-menu-options-wrapper .p-menuitem-link {
     
        font-size:.9em;
    }
    .top-menu-options-wrapper .p-menuitem-link{
        padding:0 !important;
     
        height:100%;
    }
    .top-menu-options-wrapper .p-menuitem-content{
        min-height:30px;
        height:100%;
    }
    .top-menu-options-wrapper .p-menuitem {
        width:33%;
        height:100%;
        position:relative;
    }
    .custom-menubar{
        padding:0;
    }

    .p-menubar-end{
        width:90%
    }

    

}
@media screen and (max-width: 767px) {
    /* Table and table cell styling for smaller screens */
    table {
        border: 0;
    }

    table thead {
        display: none;
    }
    table tr {
        display: block;
        border-bottom: 2px solid #17202A;
    }
    table td {
        width: 100% !important; /* Make each cell take full width */
        display: flex; /* Use flex layout */
        align-items: center; /* Center align content vertically */
        border-bottom: 1px dotted #ccc;
     
        background-color: white;
    }
    table td:last-child {
        border-bottom: 0;
    }

    /* Make data-label occupy fixed space */
    table td:before {
        content: attr(data-label) ": "; /* Adds a colon after the label */
        font-weight: bold;
        display: inline-block;
        min-width: 90px !important; /* Set desired width for uniform label size */
        width: 90px !important; /* Set desired width for uniform label size */
        margin-right: 5px; /* Adds spacing between label and content */
        text-align: left;
    }

    /* Ensure content takes up remaining width */
    table td > * {
        
        flex-grow: 1; /* Allows content to take remaining width */
    }
    .mbsc-material .mbsc-textfield-wrapper{
        margin:0 !important;
    }
    .p-inputgroup-addon{
        flex-basis: 45%;
        flex-shrink: 0; /* Prevent shrinking */
        flex-grow: 0; /* Prevent growing */
    
        
    }

    .material-label{
        flex-basis:45%;
       
      
    }
    .p-textarea-addon{
        flex-basis: 50%;
    }
    .listItem {
        padding: 0.1em;
    }
    .homeSearch{
        display:inline;
    }
    .accountNotesStyle{
        height:10em;
        padding-top:.5em;
    }
    .popUpButton{
        width:6em;
    }
    .mbsc-grid{
        padding:0 !important;
    }
}

.p-autocompleteinputgroup-addon{
    flex-basis: 25%;
    flex-shrink: 0; /* Prevent shrinking */
    flex-grow: 0; /* Prevent growing */

}


.settings-checkbox-input{
    white-space: nowrap;
    flex-basis: 70%;
  
    padding:1.1em  !important;
}
.md-btn{
    background: #3f729b;
    height:100%;
    color: #eee !important;

    padding-top: 0.05em !important;
    padding-right: 0.5em !important;
    padding-bottom: 0.05em !important;
    padding-left: 0.25em !important;
    border: 0 !important;
    border-radius: .25em !important;
    margin: .25em .25em !important;
}
.md-btn:hover, .md-btn:focus {
    outline: none !important;
 
   box-shadow: 0 0 10px #719ECE;
   transition-duration: 0;
}

.mbsc-ic.icon {
    font-family: inherit;
    font-weight: normal;
}
.mbsc-ic.icon:before {
    font-family:'Font Awesome\ 5 Free', 'Font Awesome\ 5 Brands', 'Font Awesome 5 Pro';
}   
.mbsc-ic.icon.fas:before,
.mbsc-ic.icon.fa:before {
    font-weight: 900;
}
    
.p-datatable .p-datatable-tbody > tr > td {
    padding: .1em .1em !important;
  
}
.p-datatable .p-datatable-thead > tr > th {
    padding:0;
}
.p-inputtext {
    padding: .2rem, 2rem;

}
.p-datepicker.p-component {
    min-width: 320px !important; /* Adjust this value as needed */
    width: 320px !important; 
}
th {
    border-spacing:0 !important;
    border: 1px solid #dee2e6;
}

.tableInput {
    background-color:"white";
    padding:.5em !important;
    width: 100%;
   
}

.mytooltip {
    position: relative;
    
}

.mytooltip::after {
    background-color: #8b8b8b;
    border-radius: 10px;
    color: #efeef1;
    display: none;
    padding: 5px 10px;
    position: absolute;
    text-align: center;
    z-index: 999;
}



.mytooltip:hover::after {
    display: inline;
}


.mytooltip::after {
    content: attr(tooltip);
    top: 0;
    right: 0;
    transform: translateX(0%) translateY(-10px);
    font-size: .75em
}

table.p-datepicker-calendar {
    all: revert; /* Reverts all inherited styles */
}
table.dontResize{
    all: revert; /* Reverts all inherited styles */
}
.p-datepicker table td {
    all: revert; /* Reverts all inherited styles */
}
.p-datepicker table td:before {
    all: revert; /* Reverts all inherited styles */
}
@media screen and (max-width: 767px) {
    table.p-datepicker-calendar {
        all: revert; /* Ensures styles are not overridden by media query */
    }
    table.dontResize{
        all: revert; /* Reverts all inherited styles */
    }
    .p-datepicker table td {
        all: revert; /* Reverts all inherited styles */
    }
    .p-datepicker table td:before {
        all: revert; /* Reverts all inherited styles */
    }
}